<template>
  <div class="panel">
    <h1>{{ decisionTable.name }}</h1>
    <div class="decisionable-table">
      <table>
        <thead>
          <tr>
            <td></td>
            <td
              v-for="(column, colIndex) in decisionTable.inputs.columns"
              v-bind:key="colIndex"
              class="condition"
            >
              <h5 @click="column.editVisible = !column.editVisible">
                Condition
              </h5>
              <span @click="column.editVisible = !column.editVisible">{{
                column.label
              }}</span>
              <img
                @click="addCondition(decisionTable)"
                class="header-add"
                src="img/header-add.svg"
                v-if="decisionTable.inputs.columns.length - 1 == colIndex"
              />
              <div class="fieldEditPopup" v-show="column.editVisible">
                <div>
                  <label>Column title</label>
                  <input
                    type="text"
                    v-model="column.label"
                    placeholder="Edit me"
                  />
                </div>
                <div>
                  <label>Column expression</label>
                  <input
                    type="text"
                    v-model="column.expression"
                    placeholder="Edit me"
                  />
                </div>
                <button @click="column.editVisible = false">Update</button>
              </div>
            </td>
            <td
              v-for="(column, colIndex) in decisionTable.outputs.columns"
              v-bind:key="colIndex"
              class="result"
            >
              <h5>Result</h5>
              <span>{{ column.label }}</span>
              <img
                @click="addResult()"
                class="header-add"
                src="img/header-add.svg"
                v-if="decisionTable.outputs.columns.length - 1 == colIndex"
              />
            </td>

            <td>
              <h5>&nbsp;</h5>
              <span>Notes</span>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, rowIndex) in decisionTable.inputs.rows"
            v-bind:key="rowIndex"
          >
            <td>{{ rowIndex + 1 }}</td>
            <td
              v-for="(item, itemIndex) in row"
              class="condition"
              v-bind:key="itemIndex"
            >
              <input type="text" v-model="item.value" placeholder="Anything" />
            </td>
            <td
              v-for="(item, itemIndex) in decisionTable.outputs.rows[rowIndex]"
              v-bind:key="itemIndex"
              class="result"
            >
              <input
                type="text"
                v-model="item.value"
                placeholder="edit me"
                v-if="
                  decisionTable.outputs.columns[itemIndex].outputType !==
                  'action'
                "
              />
              <span
                v-if="
                  decisionTable.outputs.columns[itemIndex].outputType ===
                    'action' && !!item.value
                "
                >{{
                  decisionTable.outputs.columns[itemIndex].type === "email"
                    ? "E-mail"
                    : "API"
                }}<img
                  src="img/action-email.svg"
                  v-if="
                    decisionTable.outputs.columns[itemIndex].type === 'email'
                  "
              /></span>
            </td>
            <td>
              <input type="text" placeholder="" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <br /><br />
    <button @click="jsonTable = JSON.stringify(decisionTable)">
      Magic Up Some JSON
    </button>
    <br /><br />
    <textarea style="width: 100%; height: 25rem" v-model="jsonTable"></textarea>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "MainPanel",
  setup() {
    return {
      jsonTable: ref({}),
      decisionTable: ref({
        name: "Sample Decision Table",
        hitPolicy: "First",
        inputs: {
          columns: [
            {
              label: "testString",
              expression: "input.testString",
              editVisible: false,
              predefinedValues: ["Test1", "Test2"],
            },
            {
              label: "has a testValue",
              expression: "input.testValue != null",
              editVisible: false,
              predefinedValues: [],
            },
          ],
          rows: [
            [
              { comparator: "Equals", value: "Test1" },
              { comparator: "Equals", value: true },
            ],
            [
              { comparator: "Equals", value: "Test2" },
              { comparator: "Equals", value: true },
            ],
            [
              { comparator: "Equals", value: "Test1" },
              { comparator: "Equals", value: false },
            ],
            [
              { comparator: "Equals", value: "Test2" },
              { comparator: "Equals", value: false },
            ],
          ],
        },
        outputs: {
          columns: [
            {
              label: "test1",
              outputType: "static",
              type: "string",
            },
            {
              label: "test2",
              outputType: "expression",
              type: "number",
            },
            {
              label: "test3",
              outputType: "action",
              type: "email",
            },
            {
              label: "test4",
              outputType: "action",
              type: "rest",
            },
          ],
          rows: [
            [
              { value: "Test Output 1" },
              { value: "input.testValue * (1+input.taxRate)" },
              {
                value: {
                  recipient: "joshua@joshuagraham.info",
                  subject: "subject 1",
                  body: "body content",
                },
              },
              { value: null },
            ],
            [
              { value: "Test Output 2" },
              { value: "input.testValue * input.taxRate" },
              { value: null },
              {
                value: {
                  url: "https://asdf.com/api/test1",
                  method: "POST",
                  headers: null,
                  body: '{"test": "test value"}',
                },
              },
            ],
            [
              { value: "Test Output 3" },
              { value: "input.testValue * input.taxRate" },
              {
                value: {
                  recipient: "joshua@joshuagraham.info",
                  subject: "subject 1",
                  body: "body content",
                },
              },
              { value: null },
            ],
            [
              { value: "Test Output 4" },
              { value: "input.testValue * input.taxRate" },
              { value: null },
              {
                value: {
                  url: "https://asdf.com/api/test1",
                  method: "POST",
                  headers: null,
                  body: '{"test": "test value"}',
                },
              },
            ],
          ],
        },
      }),
    };
  },
  methods: {
    addResult() {
      this.decisionTable.outputs.columns.push({});
      for (let row in this.decisionTable.outputs.rows) {
        this.decisionTable.outputs.rows[row].push(" ");
      }
    },
    addCondition() {
      this.decisionTable.inputs.columns.push({});
      for (let row in this.decisionTable.inputs.rows) {
        this.decisionTable.inputs.rows[row].push({
          comparator: "Equals",
          value: undefined,
        });
      }
    },
  },
  props: {},
};
</script>