import { createApp } from 'vue'
import App from './App.vue'

import { createAuth0 } from '@auth0/auth0-vue';

let app = createApp(App);

app.use(
    createAuth0({
        domain: "decisionable.uk.auth0.com",
        clientId: "2Smwm7sx0reFb9GL0nLR5XxmUtsHTTgq",
        authorizationParams: {
            redirect_uri: window.location.origin
        }
    })
);

app.mount('#app');