<template>
  <div class="toolbar-detail">
    <h1>Decisionable</h1>
    <ul>
      <li class="toolbar-item active">
        <img src="img/table-icon.svg" /> Decision Table
      </li>
      <li class="toolbar-item">
        <img src="img/check-icon.svg" /> Test Scenarios
      </li>
      <li class="toolbar-item"><img src="img/cloud-icon.svg" /> Deployments</li>
    </ul>
    <div class="user">
      <h3>{{ user.name }}</h3>
      <h4>{{ user.email }}</h4>
    </div>
  </div>
</template>

<script>
import { useAuth0 } from "@auth0/auth0-vue";

export default {
  name: "ToolBarDetail",
  setup() {
    const { user, isAuthenticated } = useAuth0();
    return {
      user,
      isAuthenticated,
    };
  },
  props: {
    msg: String,
  },
};
</script>

<style scoped>
.user {
  position: absolute;
  bottom: 0;
  padding: 0.25rem 0;
}

.user h3 {
  font-size: 1rem;
  font-weight: 300;
  padding: 0.5rem 0;
  margin: 0;
}
.user h4 {
  font-size: 0.75rem;
  font-weight: 300;
  padding: 0.5rem 0;
  margin: 0 0 0.5rem 0;
}
</style>