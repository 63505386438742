<template>
  <div class="container">
    <ToolBar />
    <ToolBarDetail />
    <MainPanel />
  </div>
</template>

<script>
import ToolBar from "./components/ToolBar.vue";
import ToolBarDetail from "./components/ToolBarDetail.vue";
import MainPanel from "./components/MainPanel.vue";

export default {
  name: "app",
  components: {
    MainPanel,
    ToolBar,
    ToolBarDetail,
  },
};
</script>