<template>
  <div class="toolbar">
    <img src="img/toolbarlogo.svg" class="toolbarlogo" />
    <ul>
      <li class="toolbar-item active"><img src="img/table.svg" /></li>
      <li class="toolbar-item"><img src="img/list.svg" /></li>
      <li class="toolbar-item"><img src="img/reports.svg" /></li>
      <li class="toolbar-item"><img src="img/users.svg" /></li>
      <li class="toolbar-item"><img src="img/favourites.svg" /></li>
    </ul>
    <div class="account">
      <img v-if="isAuthenticated" v-bind:src="user.picture" />
      <img v-if="!isAuthenticated" src="img/login.svg" @click="login" />
    </div>
  </div>
</template>

<script>
import { useAuth0 } from "@auth0/auth0-vue";

export default {
  name: "ToolBar",
  setup() {
    const { loginWithRedirect, user, isAuthenticated } = useAuth0();
    return {
      login: () => {
        loginWithRedirect();
      },
      user,
      isAuthenticated,
    };
  },
  props: {
    msg: String,
  },
};
</script>

<style scoped>
.account {
  position: absolute;
  bottom: 0rem;
  padding: 1.5rem 0;
  margin: 0 0.5rem;
  border-top: 1px solid #c09dfb;
}
.account img {
  width: 3rem;
  border-radius: 50%;
  padding: 0 0.5rem;
}
</style>